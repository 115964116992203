<template>
  <div>
    <b-modal
      id="delete-modal-category-new"
      hide-header
      hide-footer
      :visible="isshowModal"
    >
      <div class="d-block text-center">
        <h5>{{ title_modal }}</h5>
      </div>
      <div class="d-flex justify-content-center">
        <b-button
          class="mx-2 mt-3 btn btn-secondary"
          block
          @click="ScreenLogin()"
        >
          ログイン画面へ
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { Constants } from "../../utils/constants";
import { Api } from "../../utils/http-common";
export default {
  name: "LineLogin",
  data() {
    return {
      isshowModal: false,
      token: null,
      shopId: null,
      title_modal: null,
    };
  },
  created() {
    // call api login sns
    const { query } = this.$route;
    const shop_id = localStorage.getItem(Constants.SHOP_ID);
    Api.userRequestServer
      .get(
        `/auth/callback/line?code=${query.code}&state=${query.state}&shop_id=${shop_id}`
      )
      .then((response) => {
        const { data } = response;
        if (data.success) {
          const { access_token, user, expires_in } = data.data;
          const expires_at = new Date(
            new Date().setSeconds(new Date().getSeconds() + expires_in - 600)
          );
          const { user_type } = user;
          localStorage.setItem(Constants.TOKEN_USER, access_token);
          localStorage.setItem(Constants.USER_TYPE_USER, user_type);
          localStorage.setItem(Constants.EXPIRES_AT_USER, expires_at);
          localStorage.setItem(Constants.USER_ID, data.data.user.user_id);
          localStorage.setItem(
            Constants.NORMAL_USER_INFO,
            JSON.stringify(user)
          );
          this.$router.push({
            name: this.$route.params.shopId ? "library" : "library domain",
            params: { shopId: shop_id },
          });
        } else {
          this.title_modal = data.message;
          this.isshowModal = true;
          this.token = data.data.slag_token;
          this.shopId = data.data.shop_id;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  methods: {
    ScreenLogin() {
      if (this.token && this.token !== "undefined" && this.token !== "") {
        this.$router.push({
          name: this.$route.params.shopId ? "login shop" : "login shop domain",
          params: { shopId: this.shopId },
          query: { token: this.token },
        });
      } else {
        this.$router.push({
          name: this.$route.params.shopId ? "login shop" : "login shop domain",
          params: { shopId: this.shopId },
        });
      }
    },
  },
};
</script>
